import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

function TimelineHeader({ selectedProject, setSelectedProject, startDate, setStartDate, endDate, setEndDate, setPeriod }) {
    const [projects, setProjects] = useState([]);
    const [localPeriod, setLocalPeriod] = useState(localStorage.getItem('period') || '1');
    const [customPeriod, setCustomPeriod] = useState(false);

    useEffect(() => {
        axios.get('/api/projects/read.php')
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.error('Error fetching projects:', error);
            });
    }, []);

    const handleProjectChange = (e) => {
        const selected = e.target.value;
        setSelectedProject(selected);
        localStorage.setItem('selectedProject', selected);
    };

    const handlePeriodChange = (e) => {
        const selected = e.target.value;
        setLocalPeriod(selected);
        setPeriod(selected);
        localStorage.setItem('period', selected);
    };

    const toggleCustomPeriod = () => {
        setCustomPeriod(!customPeriod);
        if (!customPeriod) {
            setStartDate('');
            setEndDate('');
        } else {
            updateDatesBasedOnPeriod(localPeriod);
        }
    };

    const updateDatesBasedOnPeriod = (selectedPeriod) => {
        const currentDate = new Date();
        let newStartDate;

        switch (selectedPeriod) {
            case '1':
                newStartDate = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
                break;
            case '3':
                newStartDate = new Date(currentDate.setMonth(currentDate.getMonth() - 3));
                break;
            case '6':
                newStartDate = new Date(currentDate.setMonth(currentDate.getMonth() - 6));
                break;
            case '12':
                newStartDate = new Date(currentDate.setFullYear(currentDate.getFullYear() - 1));
                break;
            default:
                newStartDate = new Date();
        }

        setStartDate(newStartDate.toISOString().split('T')[0]);
        setEndDate(new Date().toISOString().split('T')[0]);
    };

    const clearDates = () => {
        setStartDate('');
        setEndDate('');
        localStorage.removeItem('startDate');
        localStorage.removeItem('endDate');
    };

    const exportTimelineToPDF = () => {
        const timelineElement = document.querySelector('.timeline-container');
        html2canvas(timelineElement).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210;
            const pageHeight = 295;
            const imgHeight = canvas.height * imgWidth / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('timeline.pdf');
        });
    };

    const handleEndDateChange = (e) => {
        const selectedEndDate = e.target.value;
        if (startDate && selectedEndDate && new Date(selectedEndDate) < new Date(startDate)) {
            alert("Дата окончания не может быть раньше даты начала.");
        } else {
            setEndDate(selectedEndDate);
        }
    };

    return (
        <header className="header bg-white b-b b-light" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <p className="h4 font-thin m-r-sm m-b-sm">Таймлайн</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <select 
                    className="form-control input-sm" 
                    value={selectedProject} 
                    onChange={handleProjectChange} 
                    style={{ width: '200px', marginRight: '15px' }}
                >
                    <option value="">Выберите проект</option>
                    {projects.map(project => (
                        <option key={project.id} value={project.id}>
                            {project.name}
                        </option>
                    ))}
                </select>
                <select 
                    className="form-control input-sm" 
                    value={localPeriod} 
                    onChange={handlePeriodChange} 
                    style={{ width: '150px', marginRight: '10px' }}
                    disabled={customPeriod}
                >
                    <option value="1">1 месяц</option>
                    <option value="3">3 месяца</option>
                    <option value="6">6 месяцев</option>
                    <option value="12">Год</option>
                </select>
                <button 
                    className={`btn btn-sm ${customPeriod ? 'btn-primary' : 'btn-default'}`} 
                    onClick={toggleCustomPeriod} 
                    style={{ marginRight: '15px' }}
                >
                    {customPeriod ? 'Произвольный период включен' : 'Произвольный период'}
                </button>
                <input 
                    type="date" 
                    className="form-control input-sm" 
                    value={startDate} 
                    onChange={(e) => setStartDate(e.target.value)} 
                    style={{ marginRight: '10px' }} 
                    disabled={!customPeriod}
                />
                <input 
                    type="date" 
                    className="form-control input-sm" 
                    value={endDate} 
                    onChange={handleEndDateChange} 
                    style={{ marginRight: '10px' }} 
                    disabled={!customPeriod}
                />
                <button 
                    className="btn btn-sm btn-default" 
                    onClick={clearDates}
                    style={{ marginRight: '10px' }}
                    disabled={!customPeriod}
                >
                    Очистить даты
                </button>
                <button 
                    className="btn btn-sm btn-info" 
                    onClick={exportTimelineToPDF}
                >
                    Скачать в PDF
                </button>
            </div>
        </header>
    );
}

export default TimelineHeader;
