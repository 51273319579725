// contentAreaFunctions.js
import axios from 'axios';

export const toggleDateSeparators = (showDateSeparators, setShowDateSeparators, tasks, setFilteredTasks, selectedProject) => {
    const newState = !showDateSeparators;
    setShowDateSeparators(newState);
    localStorage.setItem('showDateSeparators', JSON.stringify(newState));

    let filteredTasks = tasks;
    
    if (selectedProject) {
        filteredTasks = tasks.filter(task => task.project_id === parseInt(selectedProject));
    }

    setFilteredTasks(filteredTasks);
};


export const toggleUpcomingReminders = (showUpcomingReminders, setShowUpcomingReminders) => {
    const newState = !showUpcomingReminders;
    setShowUpcomingReminders(newState);
    localStorage.setItem('showUpcomingReminders', JSON.stringify(newState));
};

export const handlePageChange = (pageNumber, setCurrentPage, setActiveTaskIndex) => {
    setCurrentPage(pageNumber);
    setActiveTaskIndex(null);
};

export const addNewProject = (setProjects) => {
    const newProject = {
        name: "Новый проект",
        website: "http://example.com",
        color: "#000000",
        textColor: "#000000",
        tasks_completed: 0,
        tasks_overdue: 0,
        tasks_in_progress: 0,
        notes: 0
    };
    axios.post('/api/projects/create.php', newProject)
        .then(response => {
            fetchProjects(setProjects);
        })
        .catch(error => {
            console.error('There was an error creating the project!', error);
        });
};

export const togglePin = (e, taskId, tasks, setTasks, setFilteredTasks, selectedProject) => {
    e.stopPropagation();

    const updatedTasks = tasks.map(task => {
        if (task.id === taskId) {
            return {
                ...task,
                pinned: !task.pinned,
            };
        }
        return task;
    });

    updatedTasks.sort((a, b) => {
        if (a.passed && !b.passed) return -1;
        if (b.passed && !a.passed) return 1;
        if (a.pinned && !b.pinned) return -1;
        if (b.pinned && !a.pinned) return 1;
        return new Date(b.created_at) - new Date(a.created_at);
    });

    setTasks(updatedTasks);

    // Применяем фильтрацию по проекту, если выбран проект
    if (selectedProject) {
        const filteredTasks = updatedTasks.filter(task => task.project_id === parseInt(selectedProject, 10));
        setFilteredTasks(filteredTasks);
    } else {
        setFilteredTasks(updatedTasks);
    }

    axios.post('/api/tasks/update.php', { id: taskId, pinned: updatedTasks.find(task => task.id === taskId).pinned })
        .then(response => {
            console.log('Task pinned status updated:', response.data);
        })
        .catch(error => {
            console.error('There was an error updating the task pinned status:', error);
        });
};

export const filterTasksByProject = (projectId, tasks, setFilteredTasks, setSelectedProject) => {
    if (projectId) {
        setFilteredTasks(tasks.filter(task => task.project_id === parseInt(projectId)));
        setSelectedProject(projectId);
        localStorage.setItem('selectedProject', projectId);
    } else {
        setFilteredTasks(tasks);
        setSelectedProject('');
        localStorage.removeItem('selectedProject');
    }
};
