import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UpcomingReminders from './UpcomingReminders';

function Reminders() {
    const [reminders, setReminders] = useState([]);
    const [newReminder, setNewReminder] = useState({ text: '', reminder_date: '' });
    const [upcomingReminders, setUpcomingReminders] = useState([]);
    const [showUpcomingReminders, setShowUpcomingReminders] = useState(
        JSON.parse(localStorage.getItem('showUpcomingReminders')) ?? true
    );

    useEffect(() => {
        axios.get('/api/reminders/read.php')
            .then(response => {
                const allReminders = response.data;
                setReminders(allReminders);

                const upcoming = allReminders.filter(reminder => {
                    const reminderDate = new Date(reminder.reminder_date);
                    const today = new Date();
                    const nextWeek = new Date();
                    nextWeek.setDate(today.getDate() + 7);

                    return reminderDate <= nextWeek;
                });

                setUpcomingReminders(upcoming);
            })
            .catch(error => {
                console.error('Error fetching reminders:', error);
            });
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewReminder({ ...newReminder, [name]: value });
    };

    const handleCreateReminder = () => {
        axios.post('/api/reminders/create.php', newReminder)
            .then(response => {
                const updatedReminders = [...reminders, response.data];
                setReminders(updatedReminders);
                setNewReminder({ text: '', reminder_date: '' });

                const upcoming = updatedReminders.filter(reminder => {
                    const reminderDate = new Date(reminder.reminder_date);
                    const today = new Date();
                    const nextWeek = new Date();
                    nextWeek.setDate(today.getDate() + 7);

                    return reminderDate <= nextWeek;
                });

                setUpcomingReminders(upcoming);
            })
            .catch(error => {
                console.error('Error creating reminder:', error);
            });
    };

    const handleDeleteReminder = (id) => {
        axios.post('/api/reminders/delete.php', { id })
            .then(() => {
                const updatedReminders = reminders.filter(reminder => reminder.id !== id);
                setReminders(updatedReminders);

                const upcoming = updatedReminders.filter(reminder => {
                    const reminderDate = new Date(reminder.reminder_date);
                    const today = new Date();
                    const nextWeek = new Date();
                    nextWeek.setDate(today.getDate() + 7);

                    return reminderDate <= nextWeek;
                });

                setUpcomingReminders(upcoming);
            })
            .catch(error => {
                console.error('Error deleting reminder:', error);
            });
    };

    const toggleUpcomingReminders = () => {
        const newState = !showUpcomingReminders;
        setShowUpcomingReminders(newState);
        localStorage.setItem('showUpcomingReminders', JSON.stringify(newState));
    };

    return (
        <div>
            <header className="header bg-light lter bg-gradient b-b">
                <p>Reminders</p>
            </header>

            <UpcomingReminders
                reminders={upcomingReminders}
                showUpcomingReminders={showUpcomingReminders}
                toggleUpcomingReminders={toggleUpcomingReminders}
            />

            <div className="row">
                <div className="col-md-12">
                    <input
                        type="text"
                        name="text"
                        placeholder="О чем нужно напомнить?"
                        className="input-sm form-control"
                        value={newReminder.text}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-md-6">
                    <input
                        type="date"
                        name="reminder_date"
                        className="input-sm form-control"
                        value={newReminder.reminder_date}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-md-6">
                    <a href="#" className="btn btn-s-md btn-default" onClick={handleCreateReminder}>
                        Добавить напоминание
                    </a>
                </div>
            </div>

            <h3 style={{ marginLeft: '15px' }}>{reminders.length > 0 ? 'Активные напоминания' : 'Напоминаний сейчас нет'}</h3>
            <ul>
                {reminders.map(reminder => (
                    <li key={reminder.id} style={{ marginBottom: '15px' }}>
                        <span>{reminder.text}</span><br/>
                        <span>
                            <i className="fa fa-clock-o" style={{ marginRight: '5px' }}></i>
                            {new Date(reminder.reminder_date).toLocaleDateString()}
                        </span>&nbsp;
                        <a href="#" className="btn btn-s-md btn-danger" onClick={() => handleDeleteReminder(reminder.id)}>Удалить</a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Reminders;
