import React, { useState } from 'react';

function Footer({ showProjects, addNewProject, currentPage, setCurrentPage, totalTasks, tasksPerPage }) {
    const totalPages = Math.ceil(totalTasks / tasksPerPage);
    const maxPageButtons = 10;
    const [inputPage, setInputPage] = useState('');

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
            setInputPage(''); // Очистить инпут после перехода на страницу
        }
    };

    const handleInputChange = (e) => {
        setInputPage(e.target.value);
    };

    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            const pageNumber = parseInt(inputPage, 10);
            if (!isNaN(pageNumber)) {
                handlePageChange(pageNumber);
            }
        }
    };

    const renderPageButtons = () => {
        let buttons = [];

        if (totalPages <= maxPageButtons) {
            for (let i = 1; i <= totalPages; i++) {
                buttons.push(
                    <button
                        key={i}
                        type="button"
                        className={`btn btn-default ${i === currentPage ? 'active' : ''}`}
                        onClick={() => handlePageChange(i)}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            // Первые 5 страниц
            for (let i = 1; i <= 5; i++) {
                buttons.push(
                    <button
                        key={i}
                        type="button"
                        className={`btn btn-default ${i === currentPage ? 'active' : ''}`}
                        onClick={() => handlePageChange(i)}
                    >
                        {i}
                    </button>
                );
            }

            // Инпут для ввода страницы
            buttons.push(
                <input
                    key="input"
                    type="text"
                    value={inputPage}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    style={{ width: '40px', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}
                />
            );

            // Последние 5 страниц
            for (let i = totalPages - 4; i <= totalPages; i++) {
                buttons.push(
                    <button
                        key={i}
                        type="button"
                        className={`btn btn-default ${i === currentPage ? 'active' : ''}`}
                        onClick={() => handlePageChange(i)}
                    >
                        {i}
                    </button>
                );
            }
        }

        return buttons;
    };

    return (
        <footer className="footer bg-light lter clearfix b-t">
            <div className="btn-group">
                {renderPageButtons()}
            </div>
        </footer>
    );
}

export default Footer;
