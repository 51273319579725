import React from 'react';
import TaskItem from './TaskItem';

function TaskList({ tasks, setTasks, setFilteredTasks, activeTaskIndex, editTaskIndex, editTitle, dropdownOpenIndex, projectDropdownOpenIndex, handleTaskClick, handleDoubleClick, handleTitleChange, handleKeyDown, finishEditing, toggleDropdown, toggleProjectDropdown, closeTask, toggleDone, projects, assignTaskToProject, showDateSeparators, togglePin, selectedProject }) {
    let lastDate = null;

    return (
        <section className="scrollable wrapper" style={{ top: '110px' }}>
            <ul id="task-list" className="list-group list-group-sp">
                {tasks.map((task) => {
                    const taskDate = new Date(task.created_at).toLocaleDateString();

                    // Проверка, включены ли разделители дат
                    const dateDivider = showDateSeparators && taskDate !== lastDate ? (
                        <li key={`divider-${task.id}`} className="list-group-item divider" style={{ textAlign: 'center', background: 'transparent', border: 'none' }}>
                            <span className="task-date">{taskDate}</span>
                        </li>
                    ) : null;

                    lastDate = taskDate;

                    return (
                        <React.Fragment key={task.id}>
                            {dateDivider}
                            <TaskItem
                                task={task}
                                activeTaskId={activeTaskIndex}
                                editTaskId={editTaskIndex}
                                editTitle={editTitle}
                                dropdownOpenIndex={dropdownOpenIndex}
                                projectDropdownOpenIndex={projectDropdownOpenIndex}
                                handleTaskClick={handleTaskClick}
                                handleDoubleClick={handleDoubleClick}
                                handleTitleChange={handleTitleChange}
                                handleKeyDown={handleKeyDown}
                                finishEditing={finishEditing}
                                toggleDropdown={toggleDropdown}
                                toggleProjectDropdown={toggleProjectDropdown}
                                closeTask={closeTask}
                                toggleDone={toggleDone}
                                projects={projects}
                                assignTaskToProject={assignTaskToProject}
                                togglePin={(e, taskId) => togglePin(e, taskId, tasks, setTasks, setFilteredTasks, selectedProject)} // Передаем selectedProject
                            />
                        </React.Fragment>
                    );
                })}
            </ul>
        </section>
    );
}

export default TaskList;
